<template>
   <CCard>
      <CCardHeader>
        <div class="d-flex justify-content-between">
          {{ title }}
        </div>
      </CCardHeader>
      <CCardBody>
        <div>
            <CChartLine
              style="height:300px"
              :datasets="chartDataSet"
              :labels=dataChartX
              :options="{ maintainAspectRatio: false }"
            />
          </div>
      </CCardBody>
    </CCard>
</template>

<script>
export default {
    name: 'ChartLine',
    props: {
        title: {
            type: String,
            required: false
        },
        chartDataSet: {
            type: Array,
            required: true,
        },
        dataChartX: {
            type: Array,
            required: true,
        }
    }
}
</script>

<style>

</style>