import axios from 'axios';
import config from "@/config";

export async function getSessionsDataPerPacientID(id) {
    let data;
    const token = localStorage.token;
    await axios.get(`${config.BACK_IP}/patient/${id}/session`, {headers: {token}})
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    this.$router.push("/login")
                }
                if (error.response.status == 500) {
                    this.$router.push("/500")
                }
            }
        });
    return data;
}