<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <LimitCard :min="minimumLimit" :max="maximumLimit" />
        <ChartLine :chartDataSet="chartDataSet" :dataChartX="dataChartX" :title="`Sessiones del paciente: ${patientName} ${patientSurname}`"/>
        <AbstractTable  :fields="fields" :items="quests"  :actions="actions" title="Cuestionarios" :labelItemPerPage="'Cuestionarios por página:'" :addBtn="{}"  @seeInfo="handleSeeInfo" :noResults="'No se han encontrado cuestionarios'"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { generateField } from '@/utils/utils';
import { roundTwoDecimals } from '@/utils/math';
import {getPacientLimit } from '@/services/patients';
import {getSessionsDataPerPacientID} from '@/services/sessions';
import ChartLine from '@/components/Charts/ChartLine.vue';
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import { getPatientData } from '@/services/patients';
import { getQuestsDataBySession } from '@/services/quests';
import { changeDateFormatTable, getTimeFormat} from '@/utils/date';
import LimitCard from '@/components/Cards/LimitCard';
import Loader from '@/components/Loader.vue';
export default {
  name: "SesionTable",
  components: { LimitCard, ChartLine, AbstractTable, Loader },
  data() {
    return {
      patientName:"",
      patientSurname:"",
      patientId:null,
      quests: [],
      questId: '',
      sessions: [],
      patientId: null,
      minimumLimit: 0, 
      maximumLimit: 0,
      loading: true,
      chartDataSet: [
        {
          label: 'Mínimo',
          backgroundColor: 'rgba(181, 197, 235, 0.4)',
          borderColor: 'rgba(24, 115, 72, 0.8)',
          pointBackgroundColor: 'rgba(24, 115, 72, 0.8)',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'Máximo',
          backgroundColor: 'rgba(181, 197, 235, 0.4)',
          borderColor: 'rgba(113, 36, 150, 0.8)',
          pointBackgroundColor: 'rgba(113, 36, 150, 0.8)',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        },
        {
          label: 'Mínimo valor obtenido en la sesión',
          backgroundColor: 'rgba(181, 197, 235, 0.4)',
          borderColor: 'rgba(181, 197, 285, 1)',
          pointBackgroundColor: 'rgba(81, 97, 285, 1)',
          pointBorderColor: '#fff',
          data: []
        },
        {
          label: 'Máximo valor obtenido en la sesión',
          backgroundColor: 'rgba(200, 120, 120, 0.1)',
          borderColor: 'rgba(200, 120, 120, 1)',
          pointBackgroundColor: 'rgba(200, 120, 120, 1)',
          pointBorderColor: '#fff',
          data: []
        },
        {
          label: '',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          data: []
        }
      ],
      dataChartX: [],
      fields: [
        generateField("quest_title", "Nombre del cuestionario",{styleData: { textAlign: 'center'}}),
        generateField("need_device", "Necesidad del hardware", {type: "icon", styleData: { textAlign: 'center'}}),
        generateField("quest_date", "Día realizado", {styleData: { textAlign: 'center'}}),
        generateField("quest_min", "Mínimo", {styleData: { textAlign: 'center'}}),
        generateField("quest_max", "Máximo", {styleData: { textAlign: 'center'}}),
        generateField("actions", 'Acciones', { sorter: false, filter: false})
      ],
      actions: [
          {
            key: 'info',
            label: 'Información',
            event: 'seeInfo',
            classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
          },
      ],
    }
  },
  created() {
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getPacientID();
      this.getPatient();
      this.getLimits();
      this.getQuests();
      this.getSessions();
    },
    /**
     * Función para obtener el id del paciente
    */
    getPacientID(){
      if (!this.$route.params.id==this.patientId) return;
      this.patientId=this.$route.params.id;
    },
    /**
     * Función para obtener la información del paciente en este caso el nombre y apellidos.
    */
    getPatient(){
      getPatientData(this.patientId)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
          this.patientName=result.name;
          this.patientSurname=result.surname;
          this.loading = false;
      });
    },
    /**
     * Función en la que se obtienen las consejos y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Las consejos obtenidos por sesión.
     */
    getQuests() {
      getQuestsDataBySession(this.patientId)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
        this.quests = result
        this.quests.forEach((quest) => {
          quest.quest_id = quest.id;
          quest.quest_title = quest.title;
          quest.quest_date = quest.timestamp_init != undefined ? `${changeDateFormatTable(quest.timestamp_init)} - ${getTimeFormat(quest.timestamp_init)}` : '-';
          quest.quest_min = quest.min != undefined ? roundTwoDecimals(quest.min) : '-';
          quest.quest_max = quest.max != undefined ? roundTwoDecimals(quest.max) : '-';
          quest.icon = quest.need_device == 1 ? 'cil-check' : 'cil-x';
        })
        this.loading = false;
      });
    },
    /**
     * Función en la que se obtienen las sesiones y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Las sesiones obtenidas.
     */
    getSessions() {
      getSessionsDataPerPacientID(this.patientId)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        .then((result) => {
        this.sessions = result
        this.sessions.forEach((session) => {
          session.id_session=session.id;
          session.name = session.name;
          session.date = session.timestamp != undefined ? changeDateFormatTable(session.timestamp) : '-';
          session.min = session.min;
          session.max = session.max;
        })
        this.sessions.forEach(element => {
          if(element.min !== null & element.max !== null){
            this.dataChartX.push(element.date)
            this.chartDataSet[0].data.push(this.minimumLimit)
            this.chartDataSet[1].data.push(this.maximumLimit)
            this.chartDataSet[2].data.push(element.min)
            this.chartDataSet[3].data.push(element.max)
            this.chartDataSet[4].data.push("360")
          }
        });
        this.loading = false;
      });
    },
    /**
     * Función para obtener los limites del paciente.
     */
    getLimits() {
      getPacientLimit(this.patientId)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        .then((result) => {
          this.minimumLimit = (result.min_limit == undefined) ? 0 : result.min_limit;
          this.maximumLimit = (result.max_limit == undefined) ? 0 : result.max_limit;
          this.loading = false;
      });
    },
    /**
     * Este método se ejecuta cuando el usuario pulsa el botón Ver sesiones, redirije a otra vista para poder ver las sesiones del paciente.
     */
    handleSeeInfo(item) {
      this.$router.push(`/patient/sessions/${this.patientId}/quest/${item.quest_id}`);
    },
  },
};
</script>